<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">{{title}}</h3>
      </div>
    </div>

    <hr class="my-3">

    <ListTable
      :couponType="couponType"
      :templateType="templateType"
      :group="group">
    </ListTable>
  </div>
</template>

<script>
import ListTable from './ListTable.vue'
import retainMixin from '@/mixins/retain-mixin'

export default {
  mixins: [retainMixin],
  metaInfo () {
    return {
      title: `${this.title}`
    }
  },
  props: {
    couponType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      rKey: `Coupon${this.$form.capitalize(this.couponType)}List`,
    }
  },
  components: {
    ListTable,
  }
}
</script>

<style lang="css" scoped>
</style>
